import React, { useState } from 'react'
function CardAccordion({dropDownDatas}) {
    const [display, setDisplay] = useState(true)

    return (
        <section>
             <div onClick={() => setDisplay(prev => !prev)}
                        className={` border-gray-200 cursor-pointer`}>
                        <div className={`${display ? '' : 'bg-white text-indigo-950'}
                            px-6 py-4 flex justify-between items-center rounded-t-lg transition-colors duration-350`}>
                            <div className="flex items-center">
                                <h2 className="font-medium text-indigo-950 font-heading">
                                  
                                  {
                                    display? "See more" : "See less"
                                  }
                                </h2>
                            </div>
                            <div className={`${display ? ' rotate-0' : 'text-black rotate-135'} select-none text-2xl transition-all`}>+</div>
                        </div>
                        <div className={`${display ? 'hidden' : 'block'} bg-white  font-body overflow-hidden px-4 transition-all duration-500 text-gray-800`}>
                        <ul className='list-disc px-5'>
                            {dropDownDatas.map((dropDownData, index) => <li key={index} className='py-1'>{dropDownData}</li>)}
                        </ul>
                     </div>
                    </div>
        </section>
    )
}

export default CardAccordion
