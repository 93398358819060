import React, { useEffect } from "react";
import { AboutCompany, Accordion, Testimonials, Web } from "../components";
import dots from "../assets/images/dots.png";
import ximg from "../assets/images/x.png";
import timg from "../assets/images/t.png";
import oimg from "../assets/images/o.png";
import rimg from "../assets/images/r.png";
import teams from "../assets/images/teams.svg";

function AboutPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <div className="relative bg-[#f5f3f3] py-40 overflow-hidden">
        <Web />
        <div className="container relative z-10 mx-auto px-4">
          <div className="text-white text-center mb-8">
            <h1 className="font-bold text-[#4B91AB] font-heading text-4xl md:text-4xl mt-3 mb-5 capitalize">
              About Us
            </h1>
            <p className="text-gray-600 text-left max-w-2xl md:text-lg font-medium font-body mx-auto">
              LEAN Pharmaceutical is known for being one stop solution for all
              your hormone analyzer demands. From point of care fluorescence
              imminoassay (FIA) to cutting edge diagnostic chemiluminescence
              (CLIA) hormone analyzers, LEAN is where you find all varieties of
              immunoassay analyzers.<br/> From medium clinics, primary hospitals,
              speciality and surgical centers upto diagnostic laboratories, we
              got you covered for all your needs in the immunoassay tests.<br/> We're
              known for ethical business, delivered promises, the best after
              sales service and uninterrupted reagent supply. We're glad to be
              working with you and bring quality care, health and wellness to
              new heights.
            </p>
          </div>
        </div>
        <div className="absolute bottom-1/5 left-1/4 animate-grow z-0">
          <img src={dots} alt="" className="max-w-full h-auto" />
        </div>
        <div className="absolute top-12 left-1/2 animate-spin-slow z-0">
          <img src={timg} alt="" className="max-w-full h-auto" />
        </div>
        <div className="absolute bottom-0 right-1/3 animate-spin animate-movearound z-0">
          <img src={ximg} alt="" className="max-w-full h-auto" />
        </div>
        <div className="absolute bottom-1/3 left-10 animate-spin-slow z-0">
          <img src={rimg} alt="" className="max-w-full h-auto" />
        </div>
        <div className="absolute bottom-20 left-1/3 animate-movearound z-0">
          <img src={oimg} alt="" className="max-w-full h-auto" />
        </div>
        <div className="absolute top-1/2 right-20 animate-spin-slow z-0">
          <img src={timg} alt="" className="max-w-full h-auto" />
        </div>
        <img
          src={teams}
          alt=""
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-250 opacity-10 w-4/6 md:w-2/5 max-h-96"
        />
      </div>

      <AboutCompany />
      <Accordion />
      {/* <Team /> */}
      {/* <Testimonials /> */}
    </div>
  );
}

export default AboutPage;
