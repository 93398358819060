import React from 'react'
import Flickity from 'react-flickity-component'

const flickityOptions = {
    initialIndex: 1,
    prevNextButtons: false,
    wrapAround: true,
    draggable: true
}

function Testimonials() {
    return (
        <div className="pb-32 lg:px-20 mb-0">
            <div className="container mx-auto px-4">
                <div className="text-center mb-8">
                    <p className="text-[#4B91AB] text-lg md:text-xl font-heading">Testimonials</p>
                    <h1 className="text-[#2E5969] font-bold font-heading text-2xl md:text-2xl mb-5">What our customers say about us</h1>
                    {/* <p className="text-gray-700 max-w-3xl font-heading mx-auto">Lorem ipsum dolor sit amet consecte inventore animi, ratione, iure cum debitis autem explicabo!</p> */}
                </div>
            </div>
            <div className="relative container mx-auto pt-8 outline-none">
                <Flickity
                    className={'carousel'}
                    elementType={'div'}
                    options={flickityOptions}
                    disableImagesLoaded={false}
                >
                    <div className="carousel-cell mr-6">
                        <div className="relative testimony bg-gray-50 flex flex-col justify-evenly shrink-0 w-full rounded-lg px-8 py-6 text-center">
                            {/* <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
                                alt="" className="w-16 h-16 rounded mx-auto" /> */}
                            <p className="text-gray-600 max-w-md leading-6 font-body mx-auto mt-3 font-light">
                               As a healthcare professional, I have relied on Lean Pharmaceuticals for all of my pharmaceutical needs. 
                               Their products are of the highest quality and I have never been disappointed with their service.
                            </p>
                            <div>
                                <h2 className="font-medium mt-4 text-xl font-heading text-gray-700">Ato Alazar Wolde</h2>
                                <p className="text-gray-500 font-heading">Distributor of pharmaceutical medicines</p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-cell mr-12">
                        <div className="relative testimony bg-gray-50 flex flex-col justify-evenly shrink-0 w-full rounded-lg  px-8 py-6 text-center">
                            {/* <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
                                alt="" className="w-16 h-16 rounded mx-auto" /> */}
                            <p className="text-gray-600 max-w-md leading-6 font-body mx-auto mt-3 font-light">
                              I have recommended Lean Pharmaceuticals to all of my friends and family. Their products are reliable, 
                              effective, and affordable. Plus, the customer service is exceptional.
                            </p>
                            <div>
                                <h2 className="font-medium mt-4 text-xl font-heading text-gray-700">Ato Tinsae worku</h2>
                                <p className="text-gray-500 font-body">Clinical Manager</p>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-cell mr-12">
                        <div className="relative testimony bg-gray-50 flex flex-col justify-evenly shrink-0 w-full rounded-lg  px-8 py-6 text-center">
                            {/* <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4&w=256&h=256&q=60"
                                alt="" className="w-16 h-16 rounded mx-auto" /> */}
                            <p className="text-gray-600 max-w-md leading-6 font-body mx-auto mt-3 font-light">
                               I was impressed by the knowledge and expertise of the staff at Lean Pharmaceuticals.
                                They took the time to understand my needs and provided me with the best solutions for my need.
                            </p>
                            <div>
                                <h2 className="font-medium mt-4 text-xl font-heading text-gray-700">Ato Alexander Fkadu</h2>
                                <p className="text-gray-500 font-body">Pharmacist</p>
                            </div>
                        </div>
                    </div>
                </Flickity>
            </div>
        </div>
    )
}

export default Testimonials
