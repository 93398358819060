import hormn from "../assets/projects/hormn.png";
import accre90Img from "../assets/projects/accre90.png";
import accre8Img from "../assets/projects/accre8.png";
import accre6Img from "../assets/projects/accre6.png";
import fs113Img from "../assets/projects/fs113.png";
import fs205Img from "../assets/projects/fs205.png";

export const productData = [
  {
    imageTop: accre6Img,
    title: "Accre 6 — Automatic Chemiluminescence Immunoassay Analyzer",
    imageIcons: [],
    category: [],
    detailInfo: [
      "Small sample size 36 T/H.",
      "6 Channels.",
      "Mini size bench-top analyzer.",
      "Only 35 kg of weight.",
      "Less than 50% cost of regular CLIA machine.",
      "Zero cost for analyzer consumables.",
      "Only four steps to get the results.",
    ],
  },

  {
    imageTop: accre8Img,
    title: "Accre 8 — Automatic Chemiluminescence Immunoassay Analyzer",
    imageIcons: [],
    category: [],
    detailInfo: [
      "Small sample size 36 T/H.",
      "6 Channels.",
      "Mini size bench-top analyzer.",
      "Only 42 kg of weight.",
      "15.6-inch touch screen.",
      "Delicate ergonomic design.",
      "No liquid path inside.",
      "Innovative integrated design of magnetic separation and pipetting system.",
    ],
  },
  {
    imageTop: accre90Img,
    title: "Accre 90 — Automatic Chemiluminescence Immunoassay Analyzer",
    imageIcons: [],
    category: [],
    detailInfo: [
      " Middle sample size 96 T/H.",
      "16 Channels (2 Chambers).",
      "STAT mode supported.",
      "2 independent modules with 16 channels totally.",
      "10 mins for the first result.",
      "15.6-inch touch screen.",
      "Zero cost for analyzer consumables.",
      "600 days MTBF.",
      "Simultaneous processing of different parameters.",
      "No liquid path inside, maintenance-free.",
    ],
  },
  {
    imageTop: fs113Img,
    title: "Finecare™ FIA Meter Plus (FS 113)",
    imageIcons: [],
    category: [],
    detailInfo: [
      "Efficient Data Management.",
      "HIS/LIS connection.",
      "Portable & Easy-to-use.",
      "Small in size, suitable for areas with limited spaces.",
      "Built-in temperature control.",
      "Cost-efficient.",
      "Broad test menu.",
      "Reagents can be stored at 2-30 ⁰c.",
      "Fast & Reliable Results in 3-15 minutes.",
      "full traceability.",
    ],
  },
  {
    imageTop: fs205Img,
    title: "Finecare™ FIA Meter III Plus (FS 205)",
    imageIcons: [],
    category: [],
    detailInfo: [
      "Rapid Test.",
      "Fast Detection.",
      "Running 200 tests per hour.",
      "Multi-Channel for Higher Throughput.",
      "20 channels, test more items at one time.",
      "Accurate & Reliable.",
      "Good Correlation with CLIA method.",
      "Temperature Control.",
      "Provide Better Reaction Condition.",
    ],
  },
  {
    imageTop: hormn,
    title: "Finecare™ FIA Meter",
    imageIcons: [],
    category: [],
    detailInfo: ["Analyzer", "Medical Device"],
  },
];