import React from 'react'
import p1 from '../assets/images/p1.png'
import p2 from '../assets/images/p2.png'
import p3 from '../assets/images/p3.png'
import p4 from '../assets/images/p4.png'

function Process() {
    return (
        <div className="bg-[#62a0b9] py-28 md:px-20">
            <div className="container mx-auto px-4">
                <div className="text-white text-center mb-12">
                    <p className="text-[#2E5969] text-lg md:text-xl font-heading">Streamlined Procurement Process </p>
                    <h1 className="font-semibold text-center font-heading text-2xl md:text-xl mb-5">Here is a simplified overview of the process of importing pharmaceutical products and delivering them to customers.</h1>
                    {/* <p className="text-gray-300 max-w-3xl font-heading mx-auto">Engineering digital products from research to live production!</p> */}
                </div>
                <div className="relative grid grid-cols-1 md:grid-cols-4 gap-8 md:gap-8 mb-5 overflow-hidden">
                    <div className="relative mb-0 text-center">
                        <span className="invisible md:visible absolute top-8 -right-3 translate-x-1/2 w-3/5 border-dashed border-b border-slate-500"></span>
                        <div className="relative z-10 inline-flex justify-center items-center mb-8 p-3 rounded-lg bg-gray-950
                            ">
                            <img src={p1} alt="" className="w-8 h-8" />
                        </div>
                        <div className="text-white">
                            <h3 className="text-xl font-semibold font-heading mb-3">Sourcing</h3>
                            <p className="text-gray-300 text-sm font-body leading-6">
                       We carefully source the highest quality medical products from trusted suppliers around the world, ensuring that all of our products meet
                       the necessary regulatory standards.    
                            </p>
                        </div>
                    </div>
                    <div className="relative mb-0 text-center">
                        <span className="invisible md:visible absolute top-8 -right-3 translate-x-1/2 w-3/5 border-dashed border-b border-slate-500"></span>
                        <div className="relative z-10 inline-flex justify-center items-center mb-8 p-3 rounded-lg bg-slate-500
                             md:bg-transparent gray-bg gray-bg--2">
                            <img src={p2} alt="" className="w-8 h-8" />
                        </div>
                        <div className="text-white">
                            <h3 className="text-xl font-semibold font-heading mb-3">Quality Control</h3>
                            <p className="text-gray-300 text-sm font-body leading-6">
                                Our in-house quality control team thoroughly inspects and tests each product before it is cleared for shipment to our customers. 
                                This ensures that our products are safe, effective, and reliable.  
                            </p>
                        </div>
                    </div>
                    <div className="relative mb-0 text-center">
                        <span className="invisible md:visible absolute top-8 -right-3 translate-x-1/2 w-3/5 border-dashed border-b border-slate-500"></span>
                        <div className="relative z-10 inline-flex justify-center items-center mb-8 p-3 rounded-lg bg-slate-500
                             md:bg-transparent gray-bg gray-bg--3">
                            <img src={p3} alt="" className="w-8 h-8" />
                        </div>
                        <div className="text-white">
                            <h3 className="text-xl font-semibold font-heading mb-3">Logistics</h3>
                            <p className="text-gray-300 text-sm font-body leading-6">
                             Our streamlined logistics process ensures that products are transported quickly and efficiently from our warehouses to our customers, 
                             with a focus on safety, security, and cost-effectiveness.
                            </p>
                        </div>
                    </div>
                    <div className="relative mb-0 text-center">
                        <div className="relative z-10 inline-flex justify-center items-center mb-8 p-3 rounded-lg bg-slate-500
                             md:bg-transparent gray-bg gray-bg--4">
                            <img src={p4} alt="" className="w-8 h-8" />
                        </div>
                        <div className="text-white">
                            <h3 className="text-xl font-semibold font-heading mb-3">Delivery:</h3>
                            <p className="text-gray-300 text-sm font-body leading-6">
                              We take pride in delivering our products to our customers on time and in excellent condition, providing unmatched customer support throughout
                               the process.
                            </p>
                        </div>
                    </div>
                    <div className="arrow invisible md:visible absolute translate-y-1/2 -translate-x-1/2 flex items-center 
                        justify-center w-8 h-8 border-2 border-white rounded-full text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                        </svg>
                        <span className="animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Process
