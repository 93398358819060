import React from 'react'
import CardAccordion from './CardAccordion'


function Work({ index, content }) {
    return (
        <div style={{ animationDelay: index / 8 + 's' }} className="work h-fit group flex flex-col w-full rounded-lg opacity-0 overflow-hidden transition-shadow shadow hover:shadow-md">
            <div className="relative after:absolute after:top-0 after:w-full after:h-full after:bg-black/10 overflow-hidden h-72">
                <img src={content.imageTop} alt="" className="w-full min-h-full transition-transform duration-500 group-hover:scale-105 group-focus:scale-105 " />
            </div>
            <div className="flex flex-col p-4 bg-white flex-1">
                <h1 className="max-w-full text-gray-600 transition-colors text-xl text-center font-bold font-heading">
                    {content.title}
                </h1>
                <p className="max-w-full text-justify font-body text-gray-500 my-4">
                    {content.description}
                </p>
                {(content.imageIcons.length >= 4) ? <div className="grid grid-cols-4 gap-1 justify-center">
                    {content.imageIcons.map((icon, i) => (<div key={i} className="flex justify-center items-center m-1 font-medium py-1 px-1 bg-white rounded-full text-gray-700">
                        <a id="site" href={icon.url}>
                            <div className="font-body flex flex-col items-center leading-none max-w-full flex-initial">
                                <img src={icon.img} alt="" className="w-auto h-9" />
                                <p htmlFor="#site" className="font-semibold px-1.5 font-heading text-sm whitespace-nowrap mt-1">{icon.title}</p>
                            </div>
                        </a>
                    </div>))}
                </div> :

                    <div className="grid grid-cols-3 gap-1 justify-center">
                        {content.imageIcons.map((icon, i) => (<div key={i} className="flex justify-center items-center m-1 font-medium py-1 px-1 bg-white rounded-full text-gray-700">
                            <a id="site" href={icon.url}>
                                <div className="font-body flex flex-col items-center leading-none max-w-full flex-initial">
                                    <img src={icon.img} alt="" className="w-auto h-9" />
                                    <p htmlFor="#site" className="font-semibold px-1.5 font-heading text-sm">{icon.title}</p>
                                </div>
                            </a>
                        </div>))}
                    </div>}

                <div className="mt-auto lg:flex lg:flex-cols gap-5 flex justify-center items-center ">
                    {content.category.map(val => (
                        <div className="mt-2 px-3 py-0.5 rounded-2xl bg-gray-200">
                            <span className="font-heading font-normal text-sm text-center text-gray-700">{val}</span>
                        </div>
                    ))}
                </div>
            </div>
            <CardAccordion dropDownDatas = {content?.detailInfo}/>
        </div>
    )
}

export default Work
