import React, { useLayoutEffect } from "react";
import { Work } from ".";
import { productData } from "../_helpers/productData";

function WorkGroup() {
  useLayoutEffect(() => {
    const cards = document.querySelectorAll(".work");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((ele) => {
        if (ele.isIntersecting) {
          ele.target.classList.add("animate-moveup");
        }
      });
    });
    cards.forEach((card) => {
      observer.observe(card);
    });
    return () => {
      cards.forEach((card) => {
        observer.disconnect(card);
      });
    };
  }, []);

  return (
    <div className="md:px-20">
      <div className="container mx-auto px-2 md:px-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 py-16 lg:gap-8 lg:flex-wrap">
        {productData.map((cardValue, index) => (
          <Work key={index} index={index + 1} content={cardValue} />
        ))}
      </div>
    </div>
  );
}

export default WorkGroup;
