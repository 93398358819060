import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

function NotFoundPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="flex flex-col bg-indigo-950 justify-center items-center h-screen">
            <div className="relative flex">
                <span style={{ color: '#ffffff', fontSize: '180px' }} className="font-bold leading-none mr-12">4</span>
                <svg className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 inline-block overflow-visible mx-0 h-32" x="0px" y="0px"
                    viewBox="0 0 100 100" enableBackground="new 0 0 100 100">
                    <g>
                        <path fill="#ccc" fillRule="evenodd" clipRule="evenodd" d="M50,0C22.386,0,0,22.385,0,50c0,27.613,22.386,50,50,50s50-22.387,50-50   C100,22.385,77.614,0,50,0z M83.375,82.065l-1.948-1.947l-1.309,1.309l1.947,1.948c-2.463,2.367-5.179,4.473-8.118,6.253   l-1.387-2.402l-1.604,0.926l1.385,2.399c-2.971,1.641-6.144,2.957-9.467,3.917l-0.717-2.673l-1.789,0.479l0.718,2.677   c-2.56,0.628-5.201,1.04-7.907,1.224L50,89.814l-3.18,6.36c-2.706-0.184-5.348-0.596-7.907-1.224l0.717-2.677l-1.789-0.479   l-0.716,2.673c-3.324-0.96-6.497-2.276-9.468-3.917l1.385-2.399l-1.604-0.926l-1.386,2.402c-2.938-1.78-5.655-3.886-8.118-6.253   l1.948-1.948l-1.31-1.309l-1.948,1.947c-2.367-2.463-4.473-5.18-6.252-8.118l2.402-1.387l-0.926-1.604l-2.4,1.386   c-1.64-2.972-2.957-6.144-3.917-9.469l2.672-0.716l-0.479-1.789l-2.675,0.717c-0.628-2.559-1.041-5.199-1.225-7.905l6.36-3.18   l-6.361-3.18c0.184-2.706,0.597-5.348,1.226-7.907l2.675,0.717l0.479-1.789l-2.672-0.716c0.96-3.325,2.277-6.498,3.918-9.469   l2.399,1.385l0.926-1.604l-2.402-1.387c1.779-2.938,3.884-5.654,6.251-8.117l1.949,1.949l1.31-1.31l-1.949-1.949   c2.463-2.367,5.18-4.472,8.119-6.251l1.386,2.402l1.604-0.926l-1.385-2.4c2.971-1.64,6.144-2.957,9.468-3.918l0.716,2.672   l1.789-0.479l-0.716-2.675c2.558-0.628,5.2-1.041,7.906-1.225l3.18,6.36l3.18-6.36c2.706,0.184,5.348,0.596,7.906,1.225   l-0.717,2.675l1.789,0.479l0.717-2.672c3.323,0.961,6.496,2.278,9.467,3.918l-1.385,2.4l1.604,0.926l1.387-2.402   c2.939,1.779,5.656,3.884,8.119,6.251l-1.948,1.949l1.309,1.31l1.949-1.949c2.366,2.463,4.472,5.179,6.251,8.118l-2.401,1.386   l0.926,1.604l2.398-1.385c1.642,2.971,2.958,6.144,3.919,9.469l-2.673,0.716l0.479,1.789l2.676-0.717   c0.628,2.559,1.04,5.201,1.224,7.907L89.814,50l6.36,3.18c-0.184,2.706-0.596,5.347-1.224,7.905l-2.676-0.717l-0.479,1.789   l2.673,0.716c-0.961,3.325-2.277,6.497-3.918,9.469l-2.399-1.386l-0.926,1.604l2.402,1.387   C87.848,76.886,85.743,79.603,83.375,82.065z" />
                        <path fill="#ff6347" className="needle" fillRule="evenodd" clipRule="evenodd" d="M57.092,38.082c-1.154-0.688-2.412-1.218-3.752-1.549L50,22.222l-3.339,14.311   c-2.102,0.52-4.019,1.508-5.622,2.864l-4.928-3.286l3.286,4.928c-1.356,1.603-2.345,3.519-2.865,5.622L22.222,50l14.31,3.339   c0.331,1.34,0.862,2.599,1.55,3.752l-15.86,20.687l20.687-15.859c1.154,0.688,2.412,1.219,3.751,1.55L50,77.777l3.34-14.31   c2.102-0.521,4.018-1.509,5.62-2.865l4.929,3.286l-3.285-4.929c1.355-1.603,2.344-3.519,2.863-5.621L77.777,50l-14.311-3.339   c-0.33-1.34-0.861-2.599-1.549-3.752l15.859-20.686L57.092,38.082z M39.301,55.5c-0.85-1.649-1.338-3.516-1.338-5.5   c0-6.648,5.389-12.037,12.037-12.037c1.984,0,3.85,0.488,5.5,1.338L50,43.519c-3.58,0-6.481,2.901-6.481,6.481L39.301,55.5z    M54.629,50c0,2.557-2.072,4.63-4.629,4.63c-2.557,0-4.629-2.073-4.629-4.63c0-2.557,2.072-4.629,4.629-4.629   C52.557,45.371,54.629,47.443,54.629,50z M62.037,50c0,6.647-5.389,12.037-12.037,12.037c-1.984,0-3.85-0.488-5.5-1.338l5.5-4.218   c3.579,0,6.481-2.902,6.481-6.481l4.218-5.5C61.549,46.15,62.037,48.016,62.037,50z" />
                        <circle fill="#ddd" fillRule="evenodd" clipRule="evenodd" cx="50" cy="50" r="2.778" />
                    </g>
                </svg>
                <span style={{ color: '#ffffff', fontSize: '180px' }} className="font-bold leading-none ml-16 relative z-0">4</span>
            </div>
            <p style={{ color: '#ff6347' }} className="mt-3 text-xl font-semibold">Oops! Page Not Found</p>
            <p className="mt-3 text-gray-200 font-light text-sm md:text-base">The page you were looking for could not be found</p>
            <p className="mt-6">
                <Link to="/" className="px-4 py-3 bg-blue-600 hover:bg-blue-500 focus:bg-blue-500 transition-colors text-white rounded-md">
                    Return to home page
                </Link></p>
        </div>
    )
}

export default NotFoundPage
