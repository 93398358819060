import React, { useEffect } from 'react'
import { Ceo, MainBody, Services, Process, Accordion, Testimonials } from '../components'
//import OurServices from '../components/OurServices';

function LandingPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <MainBody />
           {/* <OurServices /> */} 
            <Ceo />
           {/*<Services /> */} 
            {/* <Rank /> */}
            <Process />
            {/* <Team /> */}
            <div className="">
                <Accordion classes="bg-[#f5f3f3] mb-60 lg:mb-24" />
            </div>
           {/*<Testimonials />*/} 
        </>
    )
}

export default LandingPage
