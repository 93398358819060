import React from 'react'
import office from '../assets/images/office/office-2.jpg'

function AboutCompany() {
    return (
        <div className="bg-[#62a0b9] py-24">
            <div className="container mx-auto px-10 flex flex-col md:flex-row justify-between items-center">
                <div className="mb-8">
                    <p className="text-[#1e4755] text-lg md:text-xl font-heading">Our Company</p>
                    <h1 className="text-[#2E5969] font-bold font-heading text-2xl md:text-3xl mt-3 mb-3 md:max-w-2xl capitalize">Expertise in Pharmaceuticals: Our Commitment to Quality and Service</h1>
                    <p className="text-gray-700 max-w-2xl mx-auto mt-4 font-body">
                     Our commitment to quality and service is evident in our expertise in pharmaceuticals. We strive to provide the best products and services to our clients.
                    </p>
                    <p className="text-gray-700 max-w-2xl mx-auto mt-4 font-body">
                    Our expertise in the field of pharmaceuticals allows us to offer a wide range of products and services, including diagnostic and examination equipment, 
                    medical supplies. With a commitment to quality and safety, we work tirelessly to ensure that all of our products meet the
                    highest standards and are effective for the intended use.
                    </p>
                    <p className="text-gray-700 max-w-2xl mx-auto mt-4">
                   We strive to provide the best products and solutions for our customers and are dedicated to ensuring their satisfaction. By continually improving and 
                   expanding our knowledge, we aim to be a trusted partner in healthcare.
                    </p>
                </div>
                <div className="relative group w-full md:w-2/5">
                    <div className="invisible md:visible transition-transform duration-300 group-hover:-translate-x-1.5 group-hover:-translate-y-2.5 absolute -top-8 -left-8 bg-pink-600 w-3/5 h-28 rounded"></div>
                    <div className="invisible md:visible transition-transform duration-300 group-hover:translate-x-1.5 group-hover:translate-y-2.5 absolute -bottom-8 -right-8 bg-indigo-950 w-3/5 h-28 rounded"></div>
                    <img src={office} alt="" className="relative w-full rounded-2xl" />
                </div>
            </div>
        </div>
    )
}

export default AboutCompany
